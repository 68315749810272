/*
 * the file organization follows the 7-1 pattern architecture
 * https://sass-guidelin.es/#the-7-1-pattern
 *
 *
 * Configurations
 * -------------- */
@import "abstracts/fonts";
@import "abstracts/variables";
/*
 * Vendors
 * ------- */
@import "vendors/_bootstrap";
/*
 * Helpers
 * ------- */
/*
 * Base stuff
 * ---------- */
// @import "base/reset";
// @import "base/base";
@import "base/typography";
/*
 * Layout-related sections
 * -----------------------  */
@import "layout/header";
@import "layout/main-navigation";
@import "layout/footer";
/* @import "layout/main-navigation";
@import "layout/quick-navigation";
@import "layout/sidebar"; */


/*
 * Components
 * ---------- */
@import "components/stage";
/* @import "components/content-elements"; */


@import "components/_buttons";
@import "components/_stage-slider";
@import "components/_quicklinks";

// @import "components/_offcanvas";
// @import "components/_overlay";
/*@import "components/_fields";
@import "components/_stage-slider";
@import "components/_stage-teaser";
@import "components/_newslist";
@import "components/_eventlist";
@import "components/_gallery";
@import "components/_mobile-menu";
@import "components/footer-teaser"; */

/*
 * Page-specific styles
 * --------------------
 * !!! not necessary to load them here because of entrypoint separation being done by webpack !!! */

/*
 * Themes
 * ------
 * !!! not needed in this project !!! */