.main-menu {

    #megamenu-nav {

      & > .nav-item {

        & > .nav-link.home {
          padding-left: 0;
          height: 100%;

          &[aria-expanded="true"],
          &.trail,
          &.active,
          &:active,
          &:hover {
            background-color: transparent;
          }

          &::after {
            content: "\e909";
            font-size: 22px;
          }

          span {
            display: none;
          }
        }

        & > .nav-link {
          border-radius: 9999rem;
          display: inline-flex;
          align-items: center;
          color: $white;

          &[aria-expanded="true"],
          &.trail,
          &.active,
          &:active,
          &:hover {
            background-color: darken($primary,3%);
          }

          &.submenu {
            &::after {
              content: "";
              font-size: 12px;
              margin-left: 6px;
              display: inline-block;
              position: relative;
            }

            &[aria-expanded="true"] {
              &::after {
                transform: rotate(180deg);
              }
            }
          }

          &::after {
            /* use !important to prevent issues with browser extensions that change fonts */
            font-family: "bsz-icons" !important;
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;

            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
        }

      }
    }

}


.megamenu {
  position: fixed;
  left: 0;
  width: 100%;
  top: 140px;
  z-index: 99;

  &.collapsing {
    -webkit-transition: none;
    transition: none;
  }

  .megamenu-content {
    width: 100%;
    background-color: $white;
  }

  .megamenu-backdrop {
    background: #10213366;
    cursor: pointer;
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
}