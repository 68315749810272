.home-slider {
  .item {
    overflow: hidden;
    position: relative;

    .bg-container {
      background-color: $white;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      filter: blur(15px);
      opacity: 0.8;
      height: 100%;
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
      z-index: -2;
      transform: scale(1.2);
    }

    .desc {
      background-color: $white;
      height: 390px;
      width: calc(100% + 110px);
      position: relative;
      top: 50%;
      padding: 40px 65px;
      transform: translateY(-50%);
    }

    figure {
      margin-bottom: 0;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}


.ce_stage-slider {
  .item {
    overflow: hidden;

    .bg-container {
      background-color: $white;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      filter: blur(15px);
      opacity: 0.8;
      height: 100%;
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
      z-index: -2;
      transform: scale(1.2);
    }

    .image-block {
      padding-left: 0;
      padding-right: 0;

      @include media-breakpoint-up(lg) {
        padding-top: 75px;
        padding-bottom: 75px;
        padding-left: 1rem;
        padding-right: 1rem;
      }

      img {
        width: 100%;
      }

      .ce_image {
        figure {
          position: relative;
          line-height: 0;

          figcaption {
            position: absolute;
            bottom: 0;
            left: 0;
            display: inline-block;
            line-height: normal;
          }
        }
      }
    }

    .description-block {
      position: relative;
      padding-bottom: 55px;
      padding-top: 25px;

      @include media-breakpoint-up(lg) {
        padding-top: 65px;
        padding-bottom: 75px;
        padding-right: 2rem;
      }

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        background-color: $white;
        z-index: -1;
        position: absolute;

        @include media-breakpoint-up(lg) {
          width: 121%;
          right: 16px;
        }
      }


      .text {
        p {
          display: block; /* Fallback for non-webkit */
          display: -webkit-box;
          max-width: 100%;
          height: $font-size-base*$line-height-base*3; /* Fallback for non-webkit */
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;

          @include media-breakpoint-up(lg) {
            height: $font-size-base*$line-height-base*5; /* Fallback for non-webkit */
            -webkit-line-clamp: 5;
          }
        }
      }
    }
  }
}


.is--stage {
  .tns-nav {
    margin-top: 0;
    position: absolute;
    bottom: 15px;
    max-width: 1280px;
    left: 50%;
    transform: translateX(-50%);

    @include media-breakpoint-up(lg) {
      bottom: 25px;
    }
  }
}


.tns-outer {
  position: relative;

  .tns-nav {
    margin-top: 25px;
    max-width: 1280px;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    text-align: center;

    button {
      transition: all 0.15s;
      border-color: $primary;
      border-radius: 9999px;
      border-width: 1px;
      background-color: transparent;
      padding: 0;
      width: 10px;
      height: 10px;
      display: inline-block;
      vertical-align: middle;
      margin: 0 8px;

      &.tns-nav-active {
        background-color: $primary;
        width: 15px;
        height: 15px;
      }
    }
  }

  .tns-controls {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1280px;
    height: 60px;
    padding-right: 1rem;
    padding-left: 1rem;
    outline: none;

    button {
      background-color: rgba(122, 122, 107, 0.5);
      width: 60px;
      height: 60px;
      border: none;
      padding: 0;
      position: absolute;

      &[data-controls='prev'] {
        left: -44px;
      }

      &[data-controls='next'] {
        right: -44px;
      }

      i {
        color: $white;
        font-size: 15px;
      }
    }
  }
}