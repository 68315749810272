.header-main {
  transition: top 0.75s ease-in-out, background 0.75s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;

  &.header-scrolled {
    background-color: $primary;

    .header-logo-col {
      img {
        width: 100px;
      }
    }
  }

  .header-row {
    align-items: center;
  }

  .header-col-nav {
    .main-navigation {
      display: none;

      @include media-breakpoint-up(md) {
        display: flex;
      }
    }

    .nav-main-toggle-btn {
      padding: 10px;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  .header-logo-col {
    text-align: center;

    img {
      width: 130px;
    }
  }

  .header-col-actions {
    text-align: right;
  }
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a {
  color: $body-color;
  text-transform: uppercase;
}

.navbar a:hover, .navbar a.active, .navbar a.getstarted {
  color: $secondary;
}

.navbar a.getstarted {
  background-color: $white;
  color: #000;
  text-decoration: none;
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: $primary;
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile ul {
  display: block !important;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 30px;
  background-color: $primary;
  overflow-y: auto;
  transition: 0.3s;
}

.mobile-nav-toggle {
  color: $white;
  display: none;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 40px;
  right: 40px;
}






.menu-modal {
  .modal-dialog {
    max-width: 100%;
    margin: 0;
  }

  .modal-content {
    border-radius: 0;
    border: 0;
    text-align: center;

    .modal-menu-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .nav-item {
      font-size: 2rem;
      font-family: "Julius Sans One", sans-serif;
      font-weight: 500;
    }


    .nav-link {
      text-align: center;
      color: $secondary;
    }

    .nav-link.active {
      font-weight: normal;
      color: $primary;
    }
  }
}

#footer {
  .nav-link {
    color: $white;
    text-transform: uppercase;
    letter-spacing: 5px;
  }
}

.text-image {
  padding-bottom: 90px;

  .headline-container {
    text-align: center;
    margin-top: 80px;
    margin-bottom: 60px;
  }

  .image-container {

    @media screen and (min-width: $viewport-md) {
      margin-top: 80px;
    }
  }

  .text-container {
    background-color: $white;
    padding: 20px 20px;
    position: relative;

    @media screen and (min-width: $viewport-md) {
      padding: 80px 80px 80px 50px;
    }

    &::before {
      content: "";
      background-color: $white;
      width: 130px;
      height: 100%;
      top: 0;
      z-index: -1;
      left: -130px;
      position: absolute;
      display: inline-block;
    }
  }
}

.image-text-simple {
  padding-bottom: 90px;
  text-align: center;
}

.text-image-full {
  background-color: #144E70;
  overflow: hidden;

  .text-container {
    padding: 30px 30px;

    @media screen and (min-width: $viewport-md) {
      padding: 90px 60px 85px 100px;
    }

    span {
      color: $white;
    }
  }

  .image-container {
    position: relative;
    height: 100%;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.rooms {
  padding-bottom: 0;

  @media screen and (min-width: $viewport-md) {
    padding-bottom: 60px;
  }

  .headline-container {
    text-align: center;
    margin-top: 80px;
    margin-bottom: 60px;
  }

  .room-item {
    background-color: $white;
    margin-bottom: 40px;

    @media screen and (min-width: $viewport-md) {
      margin-bottom: 0;
    }

    .text {
      padding: 40px 50px;
      text-align: center;
    }
  }
}

.benefits {
  background-color: $primary;
  color: $secondary;
  text-align: center;
  padding-top: 90px;
  padding-bottom: 90px;

  .headline-container {
    margin-bottom: 50px;

    p {
      color: $white;
    }
  }

  .benefit-item {
    margin-bottom: 20px;

    p {
      color: $white;
    }
  }

  i {
    font-size: 40px;
    margin-bottom: 10px;
  }
}

.image-background-text {
  background-color: #007A9A;
  padding-top: 60px;
  padding-bottom: 60px;

  .text-container {
    text-align: center;
  }
}

.btn-member {
  color: $white;
  text-transform: uppercase;
}

@media (max-width: 1024px) {
  .navbar ul {
    display: none;
  }

  .mobile-nav-toggle {
    display: block;
  }
}


.btn-member {
  display: none;
}

.btn-ticket-desktop {
  display: none;
}

.sponsoren img {
  max-width: 180px;
  margin-right: 20px;
}

.footer-nav {
  margin-top: 30px;
}

#footer ul {
  list-style-type: none;
  margin-bottom: 40px;
  padding-left: 0;
}

#footer ul li {
  line-height: 1.6;
  font-size: 1.1875rem;
  font-weight: 500;
}

.footer-nav ul li {
  list-style-type: none;
  display: inline;
}

.footer-nav ul li a {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 3px;
  margin-right: 30px;
  display: inline-block;
}

.btn-ticket-mobile {
  color: #000;
  background-color: #f0d9c6;
  border-color: #f0d9c6;
}

@media (min-width: 1024px) {
  .btn-member {
    display: inline-block;
  }

  .btn-ticket-desktop {
    display: inline-block;
  }

  .btn-ticket-mobile {
    display: none;
  }
}