#stage-banner {
  height: 100vh;
  background-size: cover;
  background-position: right bottom;
  background-repeat: no-repeat;
  padding-bottom: 65px;
  background-image: url('./../../images/background-miesbach-mobile.jpg');

  @include media-breakpoint-up(md) {
    background-image: url('./../../images/background-miesbach.jpg');
  }

  .container, .row {
    height: 100%;
  }

  .container {
    position: relative;
  }

  .row {
    align-items: flex-end;
  }

  h1, h2 {
    color: $white;
  }

  img {
    margin-bottom: 45px;
  }
}

#stage-banner .container, #stage-banner .row {
  height: 100%;
}

@media (max-width: 767px) {
  #stage-banner img {
    width: 200px;
    margin-bottom: 25px;
  }
}