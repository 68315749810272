/*
 * Integrate all necessary bootstrap mixins, variables, ...
 * and override some variables specifically
 */

// disable the generation of grid classes (container, row, col)
$enable-grid-classes: true;

// disable rounded styles
$enable-rounded: true;

// disable the grid gutter to avoid auto generated horizontal paddings between columns
$grid-gutter-width: 30px;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1310px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1280px
);

$utilities: (
        "display": (
                responsive: true,
                print: true,
                property: display,
                class: d,
                values: inline inline-block block grid table table-row table-cell flex inline-flex none
        ),
);

// all functions, variables and mixins bootstrap will provide for easy usage
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/utilities/api";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/grid";

@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/placeholders";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/transitions"; // wichtig for collapse
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/type";