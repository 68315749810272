.quicklinks {
  margin-top: 40px;
  margin-bottom: 40px;

  nav {
    justify-content: center;
  }

  a {
    margin-left: 10px;
    margin-right: 10px;
  }
}