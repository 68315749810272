// @import '../../fonts/bsz-icons/style';

hr {
  border: 5px;
  border-style: dotted none none none;
  background-color: transparent;
}

.mod_article {
  &.pt-standard {
    padding-top: 70px;
  }

  &.pb-standard {
    padding-bottom: 70px;
  }
}

#geschichten {
  overflow: hidden;

  .description {
    padding: 60px 15px 5px;

    @media screen and (min-width: $viewport-md) {
      padding: 100px 50px 65px;
    }
  }
}

#programm {

  h2, h3 {
    color: $secondary;
  }

  .programm-item {
    margin-bottom: 40px;

    @media screen and (min-width: $viewport-lg) {
      margin-bottom: 0;
    }
  }

  .programm-desc {
    text-align: center;
    margin-bottom: 40px;
  }

  p {
    margin-bottom: 10px;
  }

  img {
    border: 10px solid #fff;
    margin-bottom: 20px;
    display: block;
  }

  .date {
    background-color: $secondary;
    width: 100%;
    color: $primary;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    padding-top: 15px;

    .day {
      font-size: 40px;
      line-height: 40px;
    }

  }

  h3 {
    font-weight: bold;
    margin-bottom: 10px;
  }

  p.head {
    color: $secondary;
  }
}

#kontakt {
  .programm-desc {
    text-align: center;
  }
}