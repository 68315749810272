footer {
  .footer-top {
    background-color: #11415E;
    padding: 70px 0;

    p, a {
      color: $white;
    }
  }

  .footer-bottom {
    background-color: #144E70;
  }
}