// include scss from fonts folder (font variables)
// @import '../../fonts/bsz-font/variables';

// colors needed in this project
$body-bg: #fff5ca;

$primary: #f5b959;
$secondary: #fff5ca;

$font-family-sans-serif: 'Geologica', sans-serif;
$font-size-base: 1.125rem;

$headings-font-family: 'Anton', sans-serif;

$headings-color: #583d29;
$body-color: #583d29;

$btn-padding-y: .5rem;
$btn-padding-x: 2rem;

$btn-padding-x-sm: 1rem;

$navbar-nav-link-padding-x: 2rem;


$hr-margin-y: 1.25rem;




$white: #fff; // fertig
$gray-darker: #516B76; // done
$gray-dark: #688591;; // done
$gray: #D7D5C6; // done
$gray-light: #F6F2DD; // done
$gray-lighter: #FCF9EA; // done

$brand-default: $gray-dark; // done
$brand-primary: #87BA17; // done //
$brand-primary-light: #379C99; // done
$brand-primary-lighter: #92cac8; // done
$brand-primary-lighten: #9ed6d4; // done
$brand-secondary: #83BCD6; // done //
$brand-info: $brand-secondary; // done
$brand-info-light: #ffc107; // fertig
$brand-notification: $brand-info; // fertig
$brand-success: #8bc34a; // fertig
$brand-warning: $brand-info; // fertig
$brand-danger: #f44336; // fertig

// viewports
$viewport-xs: 0;
$viewport-sm: 576px;
$viewport-md: 768px;
$viewport-lg: 992px;
$viewport-xl: 1310px;

// base settings
// $body-color: #F6F2DB; // done
// $border-color: $gray; // done
// $link-color: $brand-secondary; // done
// $link-color-hover: darken($brand-secondary, 7%); // done

// font settings
// $font-family-ubuntu: 'Ubuntu', sans-serif;
// $text-color: #777A57; // done

// $font-size-base: 18px; // done

// $h1-font-size: 38px; // done
// $h1-font-size-mobile: 25px;
// $h2-font-size: 34px; // done
// $h2-font-size-mobile: 25px;
// $h3-font-size: 28px; // done
// $h3-font-size-mobile: 25px;
// $h4-font-size: 23px; // done//
// $h4-font-size-mobile: 23px;
// $h5-font-size: 18px; // done
// $h6-font-size: 14px; // done
// $line-height-headlines: 1.1;
// $line-height-base: 1.6;
// $font-size-base: 1.1875rem;
// $font-weight-bold: 700; // done
// $font-weight-semi-bold: 600; // done
// $font-weight-medium: 500; // done
// $font-weight-normal: 400; // done
// $font-weight-light: 300;

// button settings
$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;

$btn-border-width: 2px; // done


// $btn-font-size: 20px; // done
// $btn-font-weight: $font-weight-semi-bold; // done
// $btn-padding-y: 10px; // done
// $btn-padding-x: 25px; // done
// $btn-border-radius: 9999px; // done
// $btn-border-width: 2px; // done
// $btn-color: $gray-darker; // done
// $btn-background-color: transparent; // done
// $btn-border-color: $gray-dark; // done

// $btn-font-size-hover: 20px; // done
// $btn-font-weight-hover: $font-weight-semi-bold; // done
// $btn-padding-y-hover: 10px; // done
// $btn-padding-x-hover: 25px; // done
// $btn-border-radius-hover: 9999px; // done
// $btn-border-width-hover: 2px; // done
// $btn-color-hover: $white; // done
// $btn-background-color-hover: $brand-secondary; // done
// $// btn-border-color-hover: $brand-secondary; // done

// $btn--primary--background-color: $brand-primary; // done
// $btn--primary--color: $white; // done
// $btn--primary--border-color: $brand-primary; // done

// $btn--primary--background-color-hover: darken($brand-primary, 7%); // done
// $btn--primary--color-hover: $white; // done
// $btn--primary--border-color-hover: darken($brand-primary, 7%); // done

// $btn--secondary--background-color: $brand-secondary; // done
// $btn--secondary--color: $white; // done
// $btn--secondary--border-color: $brand-secondary; // done

// $btn--secondary--background-color-hover: darken($brand-secondary, 7%); // done
// $btn--secondary--color-hover: $white; // done
// $btn--secondary--border-color-hover: darken($brand-secondary, 7%); // done


// form input settings
// $input-btn-focus-width: .2rem;
// $label-font-size: 14px;
// $input-font-size: 14px;
// $input-disabled-bg: $gray-lighter;
// $input-placeholder-color: $gray-darker;
// $form-feedback-font-size: 14px;
// $form-feedback-invalid-color: $brand-danger;
