.btn {
  &.has-icon {
    display: inline-flex;
    align-items: center;

    i {
      margin-right: 8px;
      font-size: 17px;
    }
  }
}

.btn-primary {
  @include button-variant($primary, $primary, $white, darken($primary,3%), darken($primary, 3%), $white);
}

.btn-outline-primary {
  @include button-outline-variant($primary, $primary, lighten($primary,5%), $primary);
}

.btn-white {
  @include button-variant($white, $white, $primary, darken($white,3%), darken($white, 3%), $primary);
}

.btn-light {
  @include button-variant(darken($body-bg,5%), darken($body-bg,5%), $body-color, darken($body-bg,8%), darken($body-bg, 5%), $body-color);
}