@import '../../fonts/fontawesome6/scss/fontawesome.scss';
@import '../../fonts/fontawesome6/scss/light.scss';
@import '../../fonts/fontawesome6/scss/regular.scss';
@import '../../fonts/fontawesome6/scss/solid.scss';
@import '../../fonts/fontawesome6/scss/sharp-regular.scss';
@import '../../fonts/fontawesome6/scss/brands.scss';

/* geologica-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 200;
  src: url('../../fonts/geologica/geologica-v1-latin-200.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* geologica-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/geologica/geologica-v1-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* geologica-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/geologica/geologica-v1-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* geologica-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/geologica/geologica-v1-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* geologica-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/geologica/geologica-v1-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* geologica-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/geologica/geologica-v1-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* anton-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/anton/anton-v25-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}